import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  margin-left: auto;

  @media screen and (max-width: 767px) {
    margin: 1rem 0 0 0;
    flex-basis: 100%;
  }
  > label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  > input {
    all: unset;
    padding: 6px 20px;
    font-size: 18px;
    border-bottom: 1px solid #000;

    ::placeholder {
      font-size: 18px;
      font-weight: bold;
      color: #323e5b;
    }
  }
`

const SearchBar = ({ searchedValue, handleSearch, placeholder }) => {
  return (
    <Wrapper>
      <label htmlFor="search">
        <i className="fa-solid fa-magnifying-glass"></i>
      </label>
      <input
        type="search"
        id="search"
        placeholder={placeholder ? placeholder : "Search"}
        value={searchedValue}
        onChange={(event) => handleSearch(event)}
      />
    </Wrapper>
  )
}

export default SearchBar
