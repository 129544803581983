export const getUniqueValues = (articles, objectKey, objectId) => {
  const uniqueValues = articles.reduce((accumulator, currentValue) => {
    if (!currentValue[objectKey]) return accumulator

    const valuesArr = [accumulator, currentValue[objectKey]].flat()
    const uniqueObjValues = [
      ...new Map(valuesArr.map((value) => [value[objectId], value])).values(),
    ]
    return uniqueObjValues
  }, [])
  return uniqueValues
}
